import {Component, OnInit} from '@angular/core';
import {MemberService} from "../../../shared/services/httpClient/members.service";
import {ToastrService} from "ngx-toastr";
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
  NgbPaginationConfig
} from "@ng-bootstrap/ng-bootstrap";
import {DICTIONARY_MEMBER_STATUS} from "../../../../utils/dictionaries";
import {EditMemberComponent} from "../editMember/editMember.component";
import {NotificationsSendComponent} from "../../notifications/notifications-send/notifications-send.component";
import {TakePhotoComponent} from "../take-photo/take-photo.component";
import {PrintTicketComponent} from "../print-ticket/print-ticket.component";
import {UserUtils} from '../../../../utils/userUtils';

@Component({
  selector: 'app-profile-member',
  templateUrl: './profile-member.component.html',
  styleUrls: ['./profile-member.component.scss']
})
export class ProfileMemberComponent implements OnInit {
  rows = [];

  page = 1;
  itemsPerPage = 100;
  totalPages = 1;
  totalResults = 0;

  memberData: any;

  ngbModalOptions: NgbModalOptions = {
    size: 'xl',
    ariaLabelledBy: 'modal-basic-title'
  };

  closeResult: string;


  constructor(private _memberService: MemberService, public toster: ToastrService, public modalService: NgbActiveModal, private modalsService: NgbModal, private userUtils: UserUtils) {
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  private getNotifications() {
    this._memberService.getMembersNotificationsById(this.memberData.id)
      .subscribe((resp: any) => {
        if (resp.statusCode == 200) {
          if (resp.results) {
            this.totalResults = resp.pagination.totalResults;
            this.totalPages = resp.pagination.totalPages;
            this.page = resp.pagination.currentPage;
            // this.itemsPerPage = resp.pagination.perPage
            this.rows = resp.results;
          }

        }

      },
      err => {
      });
  }

  private reloadData() {
    this._memberService.getMemberInfo(this.memberData.id)
      .subscribe((resp: any) => {
        if (resp.statusCode == 200){
          this.memberData = resp.member[0];
          this.getNotifications();
        } else {
          this.toster.error(resp.message);
        }
      }, (err) => {
        this.toster.error(err);
      });
  }

  confirmDeletePhoto(id) {
    this._memberService.deletePhotoMember(id)
      .subscribe((resp: any) => {
          if (resp.statusCode == 200) {
            this.toster.success(resp.message);
            this.modalService.close();
          } else {
            this.toster.error(resp.message);
          }

        },
        err => {
          this.toster.error(err);
        });
  }

  printLastTicket(){
    const   ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title'
    };
    const modal = this.modalsService.open(PrintTicketComponent, ngbModalOptions);
    modal.componentInstance.memberData = this.memberData;
    modal.componentInstance.isLastPayment = true;
    modal.componentInstance.isFirstTime = this.memberData?.payments?.length == 1;
    modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.reloadData();
    }, (reason) => {
      this.reloadData();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getNames() {
    return `${this.memberData.name} ${this.memberData.first_lastname} ${this.memberData.second_lastname}`;
  }

  getStatusMember(id_status: number) {
    for (const mode of DICTIONARY_MEMBER_STATUS) {
      if (id_status === mode.key) {
        return mode.value;
      }
    }
    return 'Desconocido';
  }

  getPaymentType(payment_type: number) {
    return this.userUtils.getPaymentTypeName(payment_type);
  }

  openEditModal() {
    event.stopPropagation();

    const modalRef = this.modalsService.open(EditMemberComponent, this.ngbModalOptions);
    let uid = this.memberData.id.toString();
    modalRef.componentInstance.uid = uid;

    modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.reloadData();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.reloadData();
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openSendNotificationModal() {
    const modal = this.modalsService.open(NotificationsSendComponent, this.ngbModalOptions);
    modal.componentInstance.memberData = this.memberData;
    modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getNotifications();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  takePhoto() {
    const   ngbModalOptions: NgbModalOptions = {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title'
    };
    const modal = this.modalsService.open(TakePhotoComponent, ngbModalOptions);
    modal.componentInstance.memberId = this.memberData.id;
    modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.reloadData();
    }, (reason) => {
      this.reloadData();
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  isActive(id_status: number) {
    switch (id_status){
      case 1:
      case 3:
        return true;
      default:
        return false;
    }
  }

  showTakePhoto(): boolean {
    if (this.memberData?.image_profile == null) {
      return true;
    }
    const permissionLevel = this.userUtils.getPermissionLevel();
    switch (permissionLevel) {
      case this.userUtils.roles.administrador:
      case this.userUtils.roles.gerente:
        return true;
      default:
        return false;
    }
  }

  private showStatus(status) {
    switch (status) {
      case 0: {
        return 'Eliminado'
        break;
      }
      case 1: {
        return 'Activo'
        break;
      }
      case 2: {
        return 'Inactivo'
        break;
      }
      case 3: {
        return 'Cambiar contraseña'
        break;
      }
      case 4: {
        return 'Pago Expirado'
        break;
      }
      case 5: {
        return 'Migrado'
      }
      default: {
        return 'N/A'
        break;
      }
    }
  }
}
