import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserUtils {

  roles = {
    recepcionista: 1,
    nutriologo: 2,
    telemarketing: 3,
    gerente: 99,
    administrador: 100,
    super_admin: 101
  };

  payments = {
    cash: 0,
    debitcard: 1,
    cashAndCard: 2,
    spei: 4,
    creditcard: 5
  };

  constructor() {
  }

  getUserRole(): string {
    const userData = JSON.parse(localStorage.getItem('userData'));
    return userData.role;
  }

  getPermissionLevel(): number {
    const permissionName = this.getUserRole();
    if (!permissionName) {
      return 0;
    }
    const newPermissions = this.roles;

    for (const key in newPermissions) {
      if (key === permissionName) {
        return newPermissions[key];
      }
    }
    return 0;
  };

  getPaymentTypeName(type: number): string {
    switch (type) {
      case this.payments.cash:
        return 'Efectivo';
      case this.payments.debitcard:
        return 'Tarjeta de Debito';
      case this.payments.creditcard:
        return 'Tarjeta de Credito';
      case this.payments.cashAndCard:
        return 'Efectivo y Tarjeta';
      case this.payments.spei:
        return 'Transferencia';
      default:
        return 'Desconocido';
    }
  }
}
