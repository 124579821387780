import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateTime, Settings } from 'luxon';

export function getDateLocal() {
  Settings.defaultZoneName = 'America/Mexico_City';
  return DateTime.local().toLocaleString(DateTime.DATE_HUGE);
}

export function getHourOfDate(date) {
  Settings.defaultZoneName = 'America/Mexico_City';
  return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
}

export function getMonth() {
  Settings.defaultZoneName = 'America/Mexico_City';
  return DateTime.local().setLocale('mx').toFormat('MMMM');
}



export function isDateAfterNow(date){
  const now = DateTime.local();
  const parsedDate = DateTime.fromSQL(date).toLocal();
  return now <= parsedDate;
}

export function parseDate(date: string): Date {
  const dateLuxon = DateTime.fromFormat(date, 'dd/LL/yyyy');
  dateLuxon.defaultZoneName = 'America/Mexico_City';
  return dateLuxon.toJSDate();
}

export function parseDateTime(date: string): Date {
  const dateLuxon = DateTime.fromFormat(date, 'dd/LL/yyyy TT');
  dateLuxon.defaultZoneName = 'America/Mexico_City';
  return dateLuxon.toJSDate();
}

export function formatDate(d: NgbDate): string {
  if (d === null) {
    return null;
  }

  return [
    d.year,
    (d.month < 10 ? ('0' + d.month) : d.month),
    (d.day < 10 ? ('0' + d.day) : d.day)
  ].join('-');
}
