import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  url: String;
  constructor( private httpClient: HttpClient) {
    this.url = environment.baseUrl
  }

  getMembers(page: Number, query: string, idGym: Number, idStatus: Number, flag: string, birthdayflag: string = null, limit: number = 100){
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }

    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('limit', `${limit}`);
    params = params.append('page', `${page}`);
    switch (flag){
      case 'showUnverified':
        params = params.append('showUnverified', 'true');
        break;
      case 'showNeedRenew':
        params = params.append('showNeedRenew', 'true');
        break;
      case 'showWithoutRenew':
        params = params.append('showWithoutRenew', 'true');
        break;
      case 'showBirthday':
        params = params.append('showBirthday', birthdayflag);
        break;
      case 'showToExpireWeek':
        params = params.append('showToExpire', 'true');
        params = params.append('week', 'true');
        break;
      case 'showToExpireMonth':
        params = params.append('showToExpire', 'true');
        break;

    }
    if (query != '') {
      params = params.append('query', query);
    }
    if (idGym != 0) {
      params = params.append('idGym', `${idGym}`);
    }
    if (idStatus){
      params = params.append('id_status', String(idStatus));
    }

    return this.httpClient.get( `${this.url}/api/v1/admin/members`, {params: params, headers: headers});
  }

  // tslint:disable-next-line:ban-types
  getMembersNotifications(page: Number, query: string, topic: string = null) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {token: `${user.stsTokenManager.accessToken}`};
    if (!page) {
      page = 1;
    }

    // Initialize Params Object
    let params = new HttpParams();
    params = params.append('limit', '100');
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('query', query);
    }
    if (topic) {
      params = params.append('topic', topic);
    }
    return this.httpClient.get(`${this.url}/api/v1/admin/members/notifications`, {params, headers});
  }

  // tslint:disable-next-line:ban-types
  getMembersNotificationsById(id: Number) {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {token: `${user.stsTokenManager.accessToken}`};

    // Initialize Params Object
    let params = new HttpParams();
    params = params.append('limit', '100');
    params = params.append('page', `1`);
    return this.httpClient.get(`${this.url}/api/v1/admin/members/${id}/notifications`, {params, headers});
  }

  getMembersStats(){
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = { token: `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'};
    return this.httpClient.get( `${this.url}/api/v1/admin/stats/database/members`, {headers});
  }

  postMember(data: Object) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.post(`${this.url}/api/v1/admin/members`, body, {headers});
  }

  getMemberInfo(uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    return this.httpClient.get(`${this.url}/api/v1/admin/members/${uid}`, {headers});
  }

  updateMemberInfo(data: Object, uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.patch(`${this.url}/api/v1/admin/members/${uid}`, body, {headers});
  }

  deleteMember(uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }
    return this.httpClient.delete(`${this.url}/api/v1/admin/members/${uid}`, {headers});
  }

  updateMemberStatus(data: Object, uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.patch(`${this.url}/api/v1/admin/members/${uid}/payment`, body, {headers});
  }

  postMemberMembership(data: Object, uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'};
    const body=JSON.stringify(data);
    return this.httpClient.post(`${this.url}/api/v1/admin/members/${uid}/payment`, body, {headers});
  }

  deletePhotoMember(id: String){
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }
    return this.httpClient.delete(`${this.url}/api/v1/admin/members/${id}/photo`, {headers});
  }

  sendPushNotification(data: any){
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = { token: `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'};
    const body = JSON.stringify(data);
    if (data.type === 0){
      return this.httpClient.post(`${this.url}/api/v1/admin/members/${data.id_member}/notifications`, body, {headers});
    } else {
      return this.httpClient.post(`${this.url}/api/v1/admin/members/notifications`, body, {headers});
    }
  }
  typeAhead(query: string){
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = { token: `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'};
    let params = new HttpParams();
    params = params.append('limit', '10');
    params = params.append('page', '1');
    params = params.append('query', query);

    return this.httpClient.get( `${this.url}/api/v1/admin/members/typeahead`, {params: params, headers: headers});

  }
}
