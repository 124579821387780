export class ConfigDB {
	static data = {
		settings: {
			layout_type: 'ltr',
			sidebar: {
				type: 'default',
				body_type: 'default'
			},
			sidebar_setting: 'default-sidebar',
      sidebar_backround: 'color2-sidebar'
    },
		color: {
			layout_version: 'light',
			color: 'color-2',
			primary_color: '#FEFF13',
			secondary_color: '#F7B500',
			mix_layout: 'default'
		},
		router_animation: 'fadeIn'
	}
}

