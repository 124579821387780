import {Component, OnInit} from '@angular/core';
import {MemberService} from '../../../shared/services/httpClient/members.service';
import {ToastrService} from 'ngx-toastr';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  TOPIC_NEWS,
  TOPIC_NEWS_INSURGENTES, TOPIC_NEWS_INSURGENTES_QA,
  TOPIC_NEWS_NARVARTE, TOPIC_NEWS_NARVARTE_QA,
  TOPIC_NEWS_QA,
  TOPIC_NEWS_DELVALLE, TOPIC_NEWS_DELVALLE_QA,
  topics,
  topicsQa
} from '../../../../Settings';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-notifications-send',
  templateUrl: './notifications-send.component.html',
  styleUrls: ['./notifications-send.component.scss']
})
export class NotificationsSendComponent implements OnInit {

  validationForm: FormGroup;
  show: Boolean = false;
  memberData: any;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&%'\(\)\*\+,;=.]+$/;

  constructor(private _memberService: MemberService, public toster: ToastrService, public activeModal: NgbActiveModal, private builder: FormBuilder) {
  }

  ngOnInit(): void {
    this.validationForm = this.builder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      topic: ['', this.getTopicValidator()],
      image: ['', [Validators.pattern(this.urlRegex)]],
      link: ['', [Validators.pattern(this.urlRegex)]]
    });
  }
  getTopicValidator(){
    if (this.memberData !== undefined){
      return '';
    } else {
      return Validators.required;
    }
  }

  getTopics(){
    if (environment.production){
      return topics;
    } else {
      return topicsQa;
    }
  }

  submitNotification() {
    this.show = true;
    if (this.validationForm.invalid) {
      return;
    }
    if (this.validationForm.controls.link.errors?.pattern){
      return;
    }
    if (this.validationForm.controls.image.errors?.pattern){
      return;
    }

    const notificationData = this.getNotificationData();
    this._memberService.sendPushNotification(notificationData)
      .subscribe((resp: any) => {
          if (resp.statusCode == 200) {
            this.toster.success(resp.message);
            this.activeModal.close();
          } else {
            this.toster.error(resp.message);
          }

        },
        err => {
          this.toster.error(err);
        });
  }

  getNotificationData() {
    if (this.memberData !== undefined){
      return {
        type: 0,
        title: this.validationForm.get('title').value,
        description: this.validationForm.get('description').value,
        id_member: this.memberData.id,
        author: this.getAuthor(),
        gym: this.memberData.id_gym,
        image: this.validationForm.get('image').value,
        link: this.validationForm.get('link').value
      };

    } else {
      return {
        type: 1,
        title: this.validationForm.get('title').value,
        description: this.validationForm.get('description').value,
        author: this.getAuthor(),
        gym: this.getGymByTopic(),
        topic: this.getCurrentTopic(),
        image: this.validationForm.get('image').value,
        link: this.validationForm.get('link').value
      };
    }
  }

  getAuthor(): string {
    return JSON.parse(localStorage.getItem('user')).displayName;
  }

  getGymByTopic() {
    switch (this.getCurrentTopic()) {
      default:
      case TOPIC_NEWS_QA:
      case TOPIC_NEWS:
        return 0;
      case TOPIC_NEWS_INSURGENTES_QA:
      case TOPIC_NEWS_INSURGENTES:
        return 1;
      case TOPIC_NEWS_NARVARTE_QA:
      case TOPIC_NEWS_NARVARTE:
        return 2;
      case TOPIC_NEWS_DELVALLE:
      case TOPIC_NEWS_DELVALLE_QA:
        return 3;
    }
  }

  getCurrentTopic() {
    return this.validationForm.get('topic').value;
  }

  isEmpty(value){
    return (value == null || value === '');
  }

  openLink(url: string) {
    return window.open(url);
  }


}
