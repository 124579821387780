import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Tablero"
    }
  },
  {
    path: 'members',
    loadChildren: () => import('../../modules/members/members.module').then(m => m.MembersModule),
    data: {
      breadcrumb: "Socios"
    }
  },
  {
    path: 'validations',
    loadChildren: () => import('../../modules/validations/validations.module').then(m => m.ValidationsModule),
    data: {
      breadcrumb: "Validaciones"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../modules/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Usuarios"
    }
  },
  {
    path: 'billing',
    loadChildren: () => import('../../modules/billing/billing.module').then(m => m.BillingModule),
    data: {
      breadcrumb: 'Facturación'
    }
  },
  {
    path: 'nutrition',
    loadChildren: () => import('../../modules/nutrition/nutrition.module').then(m => m.NutritionModule),
    data: {
      breadcrumb: 'Nutrición'
    }
  },
  {
    path: 'telemarketing',
    loadChildren: () => import('../../modules/telemarketing/telemarketing.module').then(m => m.TelemarketingModule),
    data: {
      breadcrumb: 'Telemarketing'
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../modules/settings/settings.module').then(m => m.SettingsModule),
    data: {
      breadcrumb: 'Configuración'
    }
  },
  {
    path: 'contact',
    loadChildren: () => import('../../modules/contact/contact.module').then(m => m.ContactModule),
    data: {
      breadcrumb: 'Contacto'
    }
  }
];
