import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../../shared/services/httpClient/users.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {NavService} from '../nav.service';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {
  public userData: any;
  private _sessionId: string;
  public showLoader: boolean = false;
  private isLogged: boolean = false;

  constructor(public afs: AngularFirestore,
              public afAuth: AngularFireAuth,
              public router: Router,
              public ngZone: NgZone,
              public toster: ToastrService,
              private cookieService: CookieService,
              private _userService: UserService,
              private navServices: NavService) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this._sessionId = this.userData;
        cookieService.set('user', JSON.stringify(this.userData));
        localStorage.setItem('user', JSON.stringify(this.userData));
        this.afAuth.idTokenResult.subscribe((tokenObject) => {
          if(tokenObject != null){
            localStorage.setItem('userData', JSON.stringify(tokenObject.claims));
          }
          if (this.isLogged) {
            this.ngZone.run(() => {
              this.isLogged = false;
              this.router.navigate(['/dashboard']);
            });
          }
        });
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('userData', null);
      }
    });
  }

  ngOnInit(): void { }

  //sign in function
  SignIn(email, password) {
    this.showLoader = true
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.isLogged = true;
        this.afAuth.idTokenResult.subscribe((tokenObject) => {
          if(tokenObject != null){
            localStorage.setItem('userData', JSON.stringify(tokenObject.claims));
          }
          this.reloadNavServices();
          this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/dashboard']);
          });
        });
      }).catch((error) => {
        this.showLoader = false;
        this.toster.error('You have enter Wrong Email or Password.');
      });
  }
  //main verification function
  SendVerificationMail() {
    return this.afAuth.currentUser.then(user => {
      user.sendEmailVerification().then(() => {});
      this.router.navigateByUrl('/dashboard');
    });
  }
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Sign out
  SignOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    return this.afAuth.signOut().then(() => {
      this.showLoader = false;
      localStorage.clear();
      this.cookieService.deleteAll('user', '/auth/login');
      this.router.navigate(['/auth/login']);
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null && user.emailVerified != false) ? true : false;
  }

  getUserInfo() {
    this._userService.getMyUserInfo()
      .subscribe((resp: any) => {
        if (resp.statusCode == 200) {
          localStorage.setItem('userData', JSON.stringify(resp.user));
          JSON.parse(localStorage.getItem('userData'));
        } else {
          localStorage.setItem('userData', null);
          this.toster.error(resp.message);
        }

      },
        err => {
          localStorage.setItem('userData', null);
          this.toster.error(err)
        });
  }

  reloadNavServices() {
    this.navServices.navItems = this.navServices.getItems();
    this.navServices.items.next(this.navServices.navItems);
  }
}
