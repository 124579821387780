import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.idToken.pipe(
      take(1), // <-------------- only emit the first value!
      mergeMap((token: any) => {
        if (token) {
          request = request.clone({setHeaders: { token: `${token}`}});
        }
        return next.handle(request);
      })
    );
  }
}
