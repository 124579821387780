import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url: String;
  constructor( private httpClient: HttpClient) {
    this.url = environment.baseUrl
  }

  getUsers() {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }
    return this.httpClient.get(`${this.url}/api/v1/admin/users`, {headers});
  } 

  getMyUserInfo() {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }
    return this.httpClient.get(`${this.url}/api/v1/admin/users/me`, {headers});
  }

  getUserInfo(uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    return this.httpClient.get(`${this.url}/api/v1/admin/members/${uid}`, {headers});
  }

  postUser(data: Object) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.post(`${this.url}/api/v1/admin/users`, body, {headers});
  }

  updateUserInfo(data: Object, uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.patch(`${this.url}/api/v1/admin/users/${uid}`, body, {headers});
  }

  updateMyUserInfo(data: Object) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}`, 'Content-Type' : 'application/json'}
    const body=JSON.stringify(data);
    return this.httpClient.patch(`${this.url}/api/v1/admin/users/me`, body, {headers});
  }

  deleteUser(uid: String) {
    let user = JSON.parse(localStorage.getItem('user'));
    const headers = { 'token': `${user.stsTokenManager.accessToken}` }
    return this.httpClient.delete(`${this.url}/api/v1/admin/users/${uid}`, {headers});
  }
}