
import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/firebase/auth.service';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {MemberService} from '../../services/httpClient/members.service';
import {DICTIONARY_MEMBER_STATUS} from "../../../../utils/dictionaries";
import {ModalDismissReasons, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ProfileMemberComponent} from "../../../modules/members/profile-member/profile-member.component";

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public searchItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string;
  public elem;
  public isOpenMobile: boolean = false;
  closeResult: string;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    public authService: AuthService,
    private memberService: MemberService,
    private modalService: NgbModal,
    public toster: ToastrService) {
    translate.setDefaultLang('es');
  }


  ngOnDestroy() {
    this.removeFix();
  }


  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.searchItems = [];
    let items = [];
    term = term.toLowerCase();

    this.memberService.typeAhead(term)
      .subscribe((resp: any) => {
        if (resp.statusCode == 200){
          if (resp.members){
            resp.members.filter(searchItems => {
              items.push(searchItems);
              this.checkSearchResultEmpty(items);
              this.searchItems = items;
            });
          }
        } else {
          items.push(this.getEmptyResult(resp.message));
          this.checkSearchResultEmpty(items);
          this.searchItems = items;
        }
      }, (err) => {
        items.push(this.getEmptyResult(err));
        this.checkSearchResultEmpty(items);
        this.searchItems = items;
      });
  }

  getEmptyResult(error: string){
    return { displayName: 'No hay resultados con tu búsqueda...', message: `${error}` };
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  isActive(id_status: number) {
    switch (id_status){
      case 1:
      case 3:
        return true;
      default:
        return false;
    }
  }

  getStatusMember(id_status: number) {
    for (const mode of DICTIONARY_MEMBER_STATUS) {
      if (id_status === mode.key) {
        return mode.value;
      }
    }
    return 'Desconocido';
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.navServices.items.subscribe(menuItems => {
      this.items = menuItems
    });
  }

  openProfile(model: any){
    this.removeFix();
    if (model.id !== undefined){
      this.memberService.getMemberInfo(model.id)
        .subscribe((resp: any) => {
          if (resp.statusCode == 200){
            this.openMemberProfile(resp.member[0]);
          } else {
            this.toster.error(resp.message);
          }
        }, (err) => {
          this.toster.error(err);
        });
    }
  }

  openMemberProfile(memberData: any){
    event.stopPropagation();
    const options: NgbModalOptions = {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title'
    };
    const modal = this.modalService.open(ProfileMemberComponent, options);
    modal.componentInstance.memberData = memberData;
    modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
