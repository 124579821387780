import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {
  url: String;

  constructor(private httpClient: HttpClient) {
    this.url = environment.baseUrl;
  }

  getGyms() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {token: `${user.stsTokenManager.accessToken}`};
    return this.httpClient.get(`${this.url}/api/v1/admin/commons/gyms`, {headers});

  }

  getNutritionist() {
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {token: `${user.stsTokenManager.accessToken}`};

    return this.httpClient.get(`${this.url}/api/v1/admin/nutrition/nutritionist`, {headers});
  }

}
