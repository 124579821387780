import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
    providedIn: 'root'
})

export class SecureInnerPagesGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let userData =  JSON.parse(localStorage.getItem('userData'));

        if (userData.role === 'admin' || userData.role === 'super_admin' || userData.role === 'administrador') {
            return true;
          } else {
            window.alert("No tienes los permisos necesarios para acceder a este menu");
            this.router.navigate(['/dashboard']);
          }
        return true;
    }
}
