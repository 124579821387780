// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://environment-qa-dot-active24-2864a.uc.r.appspot.com', //'http://localhost:8080'
  firebase: {
    apiKey: "AIzaSyBa3LAaqPE-NHFb4q-aQI_M8GL01KMBPpA",
    authDomain: "active24-2864a.firebaseapp.com",
    databaseURL: "https://active24-2864a.firebaseio.com",
    projectId: "active24-2864a",
    storageBucket: "active24-2864a.appspot.com",
    messagingSenderId: "667176607621",
    appId: "1:667176607621:web:4acdee9e4cff317bc36eec",
    measurementId: "G-SVZBZ36J0D"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
