import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/storage';
import {MemberService} from '../../../shared/services/httpClient/members.service';

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss']
})
export class TakePhotoComponent implements OnInit {

  public memberId: string = null;
  public isTookPhoto = false;
  public isUploadingPhoto = false;
  public photoUploaded = false;
  public progress = 0;
  public message: string = null;
  public showError = false;

  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  public constructor(public activeModal: NgbActiveModal, private storage: AngularFireStorage, private _memberService: MemberService) {

  }
  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.isTookPhoto = true;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  clearPhoto(){
    this.webcamImage = null;
    this.isTookPhoto = false;
    this.isUploadingPhoto = false;
    this.photoUploaded = false;
    this.progress = 0;
    this.message = null;
    this.showError = false;
  }

  submitPhoto(){
    this.isUploadingPhoto = true;
    this.progress = 0;
    this.message = 'Subiendo Foto, por favor espera';
    const ref = this.storage.ref(`users/profiles/${this.memberId}.jpg`);
    fetch(this.webcamImage.imageAsDataUrl).then(res => res.blob()).then(blob => {
      const task = ref.put(blob);
      task.percentageChanges().subscribe(percent => {
        this.progress = percent > 0 ? (percent / 2) : percent;
      });
      task.then(snap => {
        if (snap.state == 'success'){
          ref.getDownloadURL().subscribe(result => {
            this.updatePhotoUrlProfile(result);
          });
        }
      }, err => this.message = err.message);
    });
  }
  updatePhotoUrlProfile(url: string){
    this.message = 'Actualizando perfil del usuario';
    const data = {
      image_profile: url
    };
    this._memberService.updateMemberInfo(data, this.memberId)
      .subscribe((resp: any) => {
          if (resp.statusCode == 200) {
            this.isUploadingPhoto = false;
            this.photoUploaded = true;
            this.progress = 100;
            this.message = 'Se ha actualizado la foto de perfil del usuario con exito';
          } else {
            this.showError = true;
            this.message = resp.message;
          }

        },
        err => {
        this.showError = true;
          this.message = err;
        });
  }

  private getHeightByFlag(): number{
    if(this.isUploadingPhoto){
      return 375;
    }
    if (this.photoUploaded || this.showError){
      return 200;
    }
  }

  private getWidthByFlag(): number {
    if(this.isUploadingPhoto){
      return 500;
    }
    if (this.photoUploaded || this.showError){
      return 200;
    }
  }
}
