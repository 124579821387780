interface Dictionary {
  key: number;
  value: string;
}

export const DICTIONARY_PAYMENT_STATUS: Dictionary[] = [
  {key: 0, value: 'Pendiente de Validación'},
  {key: 1, value: 'Validado'},
  {key: 2, value: 'Invalido'},
  {key: 11, value: 'Desconocido'}
];

export const DICTIONARY_MEMBER_STATUS = [
  {key: 0, value: 'Borrado'},
  {key: 1, value: 'Activo'},
  {key: 2, value: 'Inactivo'},
  {key: 3, value: 'Cambiar contraseña'},
  {key: 4, value: 'Membresia vencida'},
  {key: 5, value: 'Migrado'},
  {key: 11, value: 'Desconocido'}
];

export const DICTIONARY_APPOINTMENTS_MODE = [
  {key: 1, value: 'Convencional'},
  {key: 2, value: '24 Hit'},
  {key: 3, value: 'Nutriologo'}
];
export const DICTIONARY_APPOINTMENTS_STATUS = [
  {key: 0, value: 'Error'},
  {key: 1, value: 'Agendada'},
  {key: 2, value: 'Cumplida'},
  {key: 3, value: 'Incumplida'},
  {key: 4, value: 'Cancelada'}
];

export function getMemberStatus(key) {
  return this.getValue(DICTIONARY_MEMBER_STATUS, key);
}

export function getAppointmentsStatus(key) {
  return getValue(DICTIONARY_APPOINTMENTS_STATUS, key);
}

export function getAppointmentsMode(key) {
  return getValue(DICTIONARY_APPOINTMENTS_MODE, key);
}


export function getPaymentStatus(key: number) {
  return this.getValue(DICTIONARY_PAYMENT_STATUS, key);
}

export function getValue(dictionary: Dictionary[], key: number) {
  for (const mode of dictionary) {
    if (key === mode.key) {
      return mode.value;
    }
  }
  return 'Desconocido';
}
