import { Component, OnInit } from '@angular/core';
import {PrintService, UsbDriver } from 'ng-thermal-print';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonsService} from '../../../shared/services/httpClient/commons.service';
import {ToastrService} from 'ngx-toastr';
import {UserUtils} from '../../../../utils/userUtils';

@Component({
  selector: 'app-print-ticket',
  templateUrl: './print-ticket.component.html',
  styleUrls: ['./print-ticket.component.scss']
})

export class PrintTicketComponent implements OnInit {
  status = false;
  usbPrintDriver: UsbDriver;

  printerName = 'No se ha seleccionado niguna impresora';
  // webPrintDriver: WebPrintDriver;
  // ip: string = '';

  isReprint = false;

  memberData: any;
  isLastPayment = false;
  isFirstTime = true;
  payment: any;
  gyms = [];
  gym: any = null;

  constructor(public activeModal: NgbActiveModal, private printService: PrintService, public toster: ToastrService, private commonsService: CommonsService, private userUtils: UserUtils) {
    this.usbPrintDriver = new UsbDriver();
    this.printService.isConnected.subscribe(result => {
      this.status = result;
      if (this.status){
        const service: any = this.printService.driver;
        this.printerName = this.getPrinterNameFromDevice(service?.device);
      }
    });
  }

  requestUsb() {
    this.usbPrintDriver.requestUsb().subscribe(result => {
      this.printService.setDriver(this.usbPrintDriver, 'ESC/POS');
    });
  }

  getPrinterNameFromDevice(device: any): string {
    if (!device){
      return 'No se pudo reconocer la impresora';
    }
    return `Impresora: ${device.manufacturerName}`;
  }

  // connectToWebPrint() {
  //   this.webPrintDriver = new WebPrintDriver(this.ip);
  //   this.printService.setDriver(this.webPrintDriver, 'WebPRNT');
  // }

  print() {
    this.status = false;
    this.printService.init()
      .feed(4)
      .setBold(true)
      .setJustification('center')
      .writeLine(this.gym.name)
      .setBold(false)
      .feed(2)
      .writeLine('Active 24 Fitness S.A. de C.V.')
      .writeLine('RFC. AVF171109DY5')
      .writeLine(this.gym.address)
      .feed(1)
      .writeLine('--------------------------------')
      .feed(1)
      .writeLine(`${this.payment?.payment_date}`)
      .setJustification('left')
      .writeLine(`No. Ticket:          ${this.lpad(this.payment?.id.toString(), '0', 10)}`)
      .writeLine(`${this.payment?.remision ? `Remision:            ${this.lpad(this.payment?.remision, '0', 10)}` : ''}`)
      .setJustification('center')
      .writeLine(`${this.getAttendedBy()}`)
      .feed(1)
      .writeLine('VENTA')
      .feed(1)
      .setJustification('left')
      .writeLine(`${this.payment?.months} meses de membresia ${this.formatCash(this.payment?.amount)}`)
      .writeLine(`${this.getPaymentType(this.payment?.payment_type)}`)
      .writeLine(`Total                 ${this.formatCash(this.payment?.amount)}`)
      .setJustification('center')
      .feed(1)
      .writeLine('--------------------------------')
      .feed(1)
      .writeLine('Datos del socio:')
      .feed(1)
      .setJustification('left')
      .writeLine(`ID Socio.                ${this.memberData?.id}`)
      .writeLine(`Nombre.  ${this.memberData?.name} ${this.memberData?.first_lastname} ${this.memberData?.second_lastname}`)
      .writeLine(`Inicio.               ${this.payment?.start}`)
      .writeLine(`Fin.                  ${this.payment?.end}`)
      .writeLine(`Meses contratados.            ${this.payment?.months}`)
      .setJustification('center')
      .feed(1);
    if (this.isFirstTime){
      this.printService
        .setJustification('left')
        .writeLine('Datos de ingreso a la app:')
        .feed(1)
        .writeLine(`Email.  ${this.memberData?.email}`)
        .writeLine('Password.  active24*')
        .feed(1)
        .setJustification('center')
        .writeLine('*La contrasena se cambia\nla primera vez que\ninicias sesion')
        .feed(1);
    }
    this.printService.writeLine('--------------------------------')
      .feed(1)
      .writeLine('Datos de contacto de la Sucursal')
      .feed(1)
      .writeLine(`WhatsApp.     ${this.gym.whatsapp}`)
      .feed(8)
      .cut('full')
      .flush();
    setTimeout(() => {
      this.status = this.printService.isConnected.getValue();
    }, 8000);
  }

  ngOnInit(): void {
    if (this.isLastPayment){
      this.payment = this.memberData?.last_payment;
    }
    this.getGymInfo();
  }

  getTitle(): string {
    return this.isReprint ? 'Reimprimir Ticket' : 'Imprimir Ticket';
  }

  private getGym(): string {
    if (this.payment?.gym_name){
      return this.payment.gym_name;
    } else if (this.memberData?.gym_name) {
      return this.memberData?.gym_name;
    } else {
      return 'Active 24';
    }
  }

  private formatCash(money: number): string {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(money);
  }

  private getPaymentType(id: number): string {
    return this.userUtils.getPaymentTypeName(id);
  }

  lpad(str: string, padString, length: number) {
    if(str == undefined || str == null){
      return str;
    }
    while (str.length < length) {
      str = padString + str;
    }
    return str;
  }

  getAttendedBy(): string {
    let attended = '';
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (this.payment?.name_registered_by){
      attended = this.payment?.name_registered_by;
    } else {
      attended = userData.name;
    }
    return `Atendido por ${attended}`;

  }

  private getGymInfo() {
    this.commonsService.getGyms().subscribe((resp: any) => {
        if (resp.statusCode == 200) {
          this.gyms = resp.result;
          this.setGym();
        } else {
          this.toster.error(resp.message);
        }

      },
      err => {
        this.toster.error(err);
      });
  }

  private setGym() {
    const id_gym = this.payment?.id_gym ? this.payment?.id_gym : this.memberData?.id_gym;
    if (id_gym){
      for (const gym of this.gyms){
        if (gym.id == id_gym){
          this.gym = gym;
        }
      }
    } else {
      this.gym = {
        id: 0,
        name: 'Active 24',
        whatsapp: '5530689924',
        address: 'Morena 811, Colonia Narvarte Poniente.'
      };
    }
  }
}

