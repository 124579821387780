import { Component, OnInit } from '@angular/core';
import { MemberService } from '../../../shared/services/httpClient/members.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '../../../../utils/dateUtils';
import { DateTime } from 'luxon';
import {UserUtils} from '../../../../utils/userUtils';

@Component({
  selector: 'app-editMember',
  templateUrl: './editMember.component.html',
  styleUrls: ['./editMember.component.scss']
})
export class EditMemberComponent implements OnInit {

  validationForm: FormGroup;
  uid: String;
  nameData: String;
  lastName: String;
  secondLastName: String;
  email: String;
  phoneNumber: Number;
  gym: Number;
  emergencyPhoneNumberOne: Number;
  user_status: Number;
  birthDate: string;
  memberData: any = {};
  // startMembership: String
  // endMembership: String
  // paymentDate: String
  // paymentType: Number
  // amount: Number
  phoneNumberPattern = '^[0-9]{10,}$';
  zipNumberPattern = "^[0-9]{5}$";
  submited: Boolean= false;
  updatePasswordFlag: boolean = false;
  regexName = '^([a-zA-Z\u00C0-\u00FF]{2,})+(?: [a-zA-Z\u00C0-\u00FF]+)*$';


  constructor(private _memberService: MemberService, public toster: ToastrService, public activeModal: NgbActiveModal, private editMember: FormBuilder, private userUtils: UserUtils) {  }

  async ngOnInit() {
    await this.getMemberInfo();
  }

  async getMemberInfo() {
    this._memberService.getMemberInfo(this.uid)
    .subscribe((resp: any) => {
      if (resp.statusCode == 200) {
        this.nameData = resp.member[0].name;
        this.lastName = resp.member[0].first_lastname;
        this.secondLastName = resp.member[0].second_lastname;
        this.email = resp.member[0].email;
        this.phoneNumber = resp.member[0].phone;
        this.gym = resp.member[0].id_gym.toString();
        this.emergencyPhoneNumberOne = resp.member[0].emergency_phone_1;
        this.user_status = resp.member[0].id_status;
        const date = new Date(resp.member[0].birthdate);
        this.birthDate = formatDate(new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate() + 1))

        this.validationForm = this.editMember.group({
          name: [{value: this.nameData, disabled: this.inputDisabled()}, [Validators.required, Validators.pattern(this.regexName)]],
          firstLastName: [{value: this.lastName, disabled: this.inputDisabled()}, [Validators.required, Validators.pattern(this.regexName)]],
          secondLastName: [{value: this.secondLastName, disabled: this.inputDisabled()}, [Validators.pattern(this.regexName)]],
          email: [this.email, [Validators.required, Validators.email]],
          phoneNumber: [this.phoneNumber, [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
          gym: [{ value: this.gym, disabled: this.inputDisabled()}],
          birthDate: [this.birthDate, Validators.required],
          emergencyPhoneNumberOne: [this.emergencyPhoneNumberOne, [Validators.required, Validators.pattern(this.phoneNumberPattern)]],
          updatePassword: ['']
        });
      }
      else {
        this.toster.error(resp.message);
        this.activeModal.close();
      }
    }, err => {
      this.toster.error(err);
    });
  }

  pasteDate(id: string, event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let date = DateTime.fromFormat(pastedText, "dd/MM/yyyy");
    if(date.isValid){
      this.toster.success(pastedText);
      this.validationForm.get(id).setValue(date.toISODate());
    } else {
      this.toster.error('Fecha Invalida ' + pastedText);
    }
  }

  submitEditMember() {
    this.submited = true
    if (this.validationForm.invalid) {
      return;
    }

    let gym_id = Number(this.validationForm.get('gym').value)

    if (this.validationForm.get('name').value != this.nameData) {
      this.memberData.name = this.validationForm.get('name').value
    }

    if (this.validationForm.get('firstLastName').value != this.lastName) {
      this.memberData.firstLastname = this.validationForm.get('firstLastName').value
    }

    if (this.validationForm.get('secondLastName').value != this.secondLastName) {
      this.memberData.secondLastname = this.validationForm.get('secondLastName').value
    }

    if (this.validationForm.get('email').value != this.email) {
      this.memberData.email = this.validationForm.get('email').value
    }

    if (this.validationForm.get('phoneNumber').value != this.phoneNumber) {
      this.memberData.phone = this.validationForm.get('phoneNumber').value
    }

    if (this.validationForm.get('emergencyPhoneNumberOne').value != this.emergencyPhoneNumberOne) {
      this.memberData.emergency_phone_1 = this.validationForm.get('emergencyPhoneNumberOne').value
    }

    if (gym_id != this.gym) {
      this.memberData.idGym = gym_id
    }

    if (this.updatePasswordFlag) {
      this.memberData.email = this.validationForm.get('email').value;
      this.memberData.updatePassword = this.updatePasswordFlag;
    }

    if (this.validationForm.get('birthDate').value != this.birthDate) {
      this.memberData.birthdate = this.validationForm.get('birthDate').value
    }

    if (Object.keys(this.memberData).length == 0) {
      this.toster.warning('Debes de editar minimo un dato, de lo contrario cierra la pantalla');
      return;
    }

    this._memberService.updateMemberInfo(this.memberData, this.uid)
    .subscribe((resp: any) => {
      if (resp.statusCode == 200) {
        this.toster.success(resp.message);
        this.activeModal.close()
      } else {
        this.toster.error(resp.message);
      }

    },
      err => {
        this.toster.error(err)
      });
  }

  changeflag() {
    this.updatePasswordFlag = !this.updatePasswordFlag
  }

  inputDisabled(): boolean {
    const permissionLevel = this.userUtils.getPermissionLevel();
    switch (permissionLevel) {
      case this.userUtils.roles.administrador:
      case this.userUtils.roles.gerente:
        return false;
      default:
        return true;
    }
  }
}
