export const TOPIC_NEWS = '/topics/news';
export const TOPIC_NEWS_INSURGENTES = 'news-insurgentes';
export const TOPIC_NEWS_NARVARTE = 'news-narvarte';
export const TOPIC_NEWS_DELVALLE = 'news-delvalle'

export const TOPIC_NEWS_QA = 'news-qa';
export const TOPIC_NEWS_INSURGENTES_QA = 'news-insurgentes-qa';
export const TOPIC_NEWS_NARVARTE_QA = 'news-narvarte-qa';
export const TOPIC_NEWS_DELVALLE_QA = 'news-delvalle-qa'

export const topics = [
  {name: 'Noticias Generales (Todos los gimnasios)', value: TOPIC_NEWS},
  {name: 'Noticias Active 24 Insurgentes', value: TOPIC_NEWS_INSURGENTES},
  {name: 'Noticias Active 24 Narvarte', value: TOPIC_NEWS_NARVARTE},
  {name: 'Noticias Active 24 Del Valle', value: TOPIC_NEWS_DELVALLE}

];
export const topicsQa = [
  {name: 'Noticias Generales (Todos los gimnasios) QA', value: TOPIC_NEWS_QA},
  {name: 'Noticias Active 24 Insurgentes QA', value: TOPIC_NEWS_INSURGENTES_QA},
  {name: 'Noticias Active 24 Narvarte QA', value: TOPIC_NEWS_NARVARTE_QA},
  {name: 'Noticias Active 24 Del Valle QA', value: TOPIC_NEWS_DELVALLE_QA}

];
